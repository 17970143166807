export const Partners = [
    {
        name: "디아지오",
        description: "",
        imageSrc: "/img/partners/diageo_logo.svg"
    },
    {
        name: "커버낫",
        description: "",
        imageSrc: "/img/partners/covernat.png"
    },
    {
        name: "LEE",
        description: "",
        imageSrc: "/img/partners/lee.png"
    },
    {
        name: "와릿리즌",
        description: "",
        imageSrc: "/img/partners/whatisisnt.png"
    },
    {
        name: "이벳필드",
        description: "",
        imageSrc: "/img/partners/ebbet-field.png"
    },
    {
        name: "팔렛",
        description: "",
        imageSrc: "/img/partners/fallett.png"
    },
    {
        name: "랭글러",
        description: "",
        imageSrc: "/img/partners/wrangler.png"
    },
    {
        name: "네이머클로딩",
        description: "",
        imageSrc: "/img/partners/namer-clothing.png"
    },
    {
        name: "스티브알란",
        description: "",
        imageSrc: "/img/partners/steven-alan.png"
    },
    {
        name: "토니호크",
        description: "",
        imageSrc: "/img/partners/tony-hawk.png"
    },
    {
        name: "트레셔",
        description: "",
        imageSrc: "/img/partners/thrasher.png"
    },
    {
        name: "스피드랙",
        description: "",
        imageSrc: "/img/partners/speedrack_logo.png"
    },
    {
        name: "브라이언임팩트",
        description: "",
        imageSrc: "/img/partners/logo_brian.svg"
    },
    {
        name: "코젠바이오텍",
        description: "",
        imageSrc: "/img/partners/kogen_logo.png"
    },
    {
        name: "신영와코루",
        description: "",
        imageSrc: "/img/partners/venus_logo.svg"
    },
    {
        name: "하임랩",
        description: "",
        imageSrc: "/img/partners/heimlab.svg"
    },
    {
        name: "쥬씨",
        description: "",
        imageSrc: "/img/partners/juicy.png"
    },
    {
        name: "(주)엔포유기술지주",
        description: "",
        imageSrc: "/img/partners/n4u.png"
    },
    {
        name: "대교 CNS",
        description: "",
        imageSrc: "/img/partners/dkcns_logo.png"
    },
    {
        name: "고려대학교 산학협력단",
        description: "",
        imageSrc: "/img/partners/ku_re.jpeg"
    },
    {
        name: "고려대학교 기술사업화센터",
        description: "",
        imageSrc: "/img/partners/kutlo.png"
    },
    {
        name: "굿피플",
        description: "",
        imageSrc: "/img/partners/good-people.png"
    },
    {
        name: "크레이버",
        description: "",
        imageSrc: "/img/partners/craver.png"
    },
    {
        name: "고려대학교 교육혁신센터",
        description: "",
        imageSrc: "/img/partners/kuchive_logo.png"
    },
    {
        name: "방픽파트너스",
        description: "",
        imageSrc: "/img/partners/bangpick.svg"
    },

    {
        name: "피벗포인트",
        description: "",
        imageSrc: "/img/partners/pivotpoint.png"
    },
    {
        name: "몬스터짐",
        description: "",
        imageSrc: "/img/partners/monsterzym.png"
    },
    {
        name: "크로스앵글",
        description: "",
        imageSrc: "/img/partners/crossangle.png"
    },
    {
        name: "라이트브라더스",
        description: "",
        imageSrc: "/img/partners/wrightbrothers.svg"
    },
    {
        name: "제이노블",
        description: "",
        imageSrc: "/img/partners/jnoble.png"
    },
    {
        name: "한경기획",
        description: "",
        imageSrc: "/img/partners/hangkyung-2.png"
    },
    {
        name: "한국에너지공과대학",
        description: "",
        imageSrc: "/img/partners/kentech.png"
    },
    {
        name: "삼성드림이비인후과",
        description: "",
        imageSrc: "/img/partners/samsung-dream.png"
    },
    {
        name: "메데어코리아",
        description: "",
        imageSrc: "/img/partners/medair_korea.png"
    },
    {
        name: "굿닥",
        description: "",
        imageSrc: "/img/partners/goodoc_img2.png"
    },
    {
        name: "케이투코리아",
        description: "",
        imageSrc: "/img/partners/logo-k2.png"
    },
    {
        name: "아이더",
        description: "",
        imageSrc: "/img/partners/logo-eider.png"
    },
    {
        name: "와이드앵글",
        description: "",
        imageSrc: "/img/partners/logo-wangle.png"
    },
    {
        name: "다이나핏",
        description: "",
        imageSrc: "/img/partners/logo-dynafit.png"
    },
    {
        name: "피레티",
        description: "",
        imageSrc: "/img/partners/logo-piretti.png"
    },
    {
        name: "노르디스크",
        description: "",
        imageSrc: "/img/partners/logo-nordisk.png"
    },
    {
        name: "케이투세이프티",
        description: "",
        imageSrc: "/img/partners/logo-k2safety.png"
    },
    {
        name: "아이더세이프티",
        description: "",
        imageSrc: "/img/partners/logo-EIDERSafety.png"
    },
    {
        name: "골프존카운티",
        description: "",
        imageSrc: "/img/partners/golfzone.png"
    },
    {
        name: "위버스컴퍼니",
        description: "",
        imageSrc: "/img/partners/weverse.png"
    },
    {
        name: "큐로셀",
        description: "",
        imageSrc: "/img/partners/curocell.png"
    },
    {
        name: "cafe24",
        description: "",
        imageSrc: "/img/partners/cafe24.svg"
    },
    {
        name: "LG Electronics",
        description: "",
        imageSrc: "/img/partners/lg_electronic.svg"
    },
    {
        name: "HD현대인프라코어",
        description: "",
        imageSrc: "/img/partners/hd-infracore.png"
    },
    {
        name: "젠틀몬스터",
        description: "",
        imageSrc: "/img/partners/gentle_monster.svg"
    },
    {
        name: "팔도",
        description: "",
        imageSrc: "/img/partners/paldo.png"
    }
];
